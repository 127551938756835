import { isPlatformBrowser } from '@angular/common';
import { computed, effect, inject, Injectable, Injector, PLATFORM_ID, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { map, Observable, of } from 'rxjs';

import { UserType } from '@ppg/core/enums';
import { UserInfo } from '@ppg/core/models';

import { UserApiService } from './user-api.service';
import { AuthService } from '../../../domain/repository/auth-service.abstract';

@Injectable()
export class UserService {
  private readonly authUseCases = inject(AuthService);
  private readonly userApiService = inject(UserApiService);
  readonly #user = signal<UserInfo | null>(null);
  readonly #language = signal<string | null>(null);
  readonly #injector = inject(Injector);
  readonly language = this.#language.asReadonly();
  readonly user = this.#user.asReadonly();

  readonly loaded$ = toObservable(this.user).pipe(map((user) => !!user));

  readonly firstName = computed(() => this.user()?.firstName ?? '');
  readonly lastName = computed(() => this.user()?.lastName ?? '');
  readonly fullName = computed(() => `${this.firstName()} ${this.lastName()}`.trim());
  readonly email = computed(() => this.user()?.email ?? null);
  readonly locale = computed(() => this.user()?.locale ?? null);
  readonly userKey = computed(() => this.user()?.userKey ?? null);
  readonly permissions = computed(() => this.user()?.permissions ?? []);
  readonly countryCode = computed(() => this.user()?.countryCode ?? null);
  readonly isAuthOnMobile = computed(() => this.user()?.isAuthenticatedOnMobileDevice ?? false);
  readonly isActive = computed(() => this.user()?.isActive ?? false);
  readonly userType = computed(() => this.user()?.userType ?? UserType.B2B);
  readonly isB2BUser = computed(() => this.userType() == UserType.B2B);

  readonly isAuthenticatedOnMobileDevice = computed(() => !!this.user()?.isAuthenticatedOnMobileDevice);

  readonly #loadingState = signal({ userLoaded: false, languageLoaded: false });
  readonly loadingState = this.#loadingState.asReadonly();
  readonly loadingState$ = toObservable(this.#loadingState);

  constructor() {
    effect(
      () => {
        const isAuthenticated = this.authUseCases.isAuthenticated();
        if (!isAuthenticated) {
          this.#user.set(null);
          this.#loadingState.set({ userLoaded: true, languageLoaded: true });
          // Disable SSR for E-commerce using isPlatformBrowser
        } else if (isPlatformBrowser(this.#injector.get(PLATFORM_ID))) {
          this.#fetchUserInfo();
        } else {
          // TODO: remove when "disable SSR for E-commerce" workaround will be removed
          this.#loadingState.set({ userLoaded: true, languageLoaded: true });
        }
      },
      { allowSignalWrites: true },
    );

    effect(
      () => {
        const user = this.user();
        if (!user) {
          this.#language.set(null);
        } else {
          this.#fetchLanguage();
        }
      },
      { allowSignalWrites: true },
    );
  }

  updateLanguage(language: string): Observable<void> {
    const isAuthenticated = this.authUseCases.isAuthenticated();
    const user = this.user();

    if (!isAuthenticated) {
      return of(undefined);
    }

    if (!user) {
      return of(undefined);
    }

    return this.userApiService.updateUserLanguage(language, user.email).pipe(
      map(() => {
        this.#language.set(language ?? null);
      }),
    );
  }

  private setUserInfo(user: UserInfo) {
    this.#user.set(user);
  }

  #fetchUserInfo() {
    this.userApiService.getUserInfo().subscribe((user) => {
      this.setUserInfo(user);
      this.#loadingState.update((state) => ({ ...state, userLoaded: true }));
    });
  }

  #fetchLanguage() {
    if (!this.isActive()) {
      this.#language.set(null);
      this.#loadingState.update((state) => ({ ...state, languageLoaded: true }));
      return;
    }

    const email = this.email()!;
    this.userApiService.getUserLanguage(email).subscribe((language) => {
      this.#language.set(language ?? null);
      this.#loadingState.update((state) => ({ ...state, languageLoaded: true }));
    });
  }
}
