export enum CaslAction {
  MANAGE = 'manage',
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  IMPORT = 'import',
  REQUEST_ACCESS = 'request-access',
  COPY = 'copy',
}
