import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UrlTarget } from '@ppg/core/enums';
import { CTA } from '@ppg/core/models';

@Injectable({
  providedIn: 'root',
})
export class UrlCtaService {
  readonly #router: Router = inject(Router);
  readonly #document: Document = inject(DOCUMENT);

  isAbsoluteUrlPath(url?: string): boolean {
    return url ? url.startsWith('http://') || url.startsWith('https://') || url.includes('www.') : false;
  }

  isRelativePathWithSelfTarget(url?: string, target?: UrlTarget): boolean {
    if (!url || !target) return false;

    return target === UrlTarget.SELF && !this.isAbsoluteUrlPath(url);
  }

  parseUrl(url?: string): { path?: string; anchor?: string } {
    if (!url) return {};
    const [path, anchor] = url.split('#');
    return { path, anchor };
  }

  navigate(cta: CTA | undefined): void {
    const url = cta?.content?.url ?? undefined;
    const target = cta?.content?.target ?? undefined;

    if (this.isRelativePathWithSelfTarget(url, target)) {
      const { path, anchor: fragment } = this.parseUrl(url);
      this.#router.navigate([path], { fragment });
    } else {
      this.#document.defaultView?.open(url, target);
    }
  }

  isTargetBlank(cta?: CTA): boolean {
    return cta?.content?.target === UrlTarget.BLANK;
  }
}
