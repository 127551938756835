import { isPlatformServer, isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Injectable, TransferState, makeStateKey, PLATFORM_ID, inject, signal, computed } from '@angular/core';

import { CONFIG, CONFIGURATION_KEY } from '@ppg/core/constants';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';
import { ErrorHandlerBaseService } from '@ppg/core/logger';
import { DomainName, Site, SiteSetting } from '@ppg/core/models';

import { SiteService } from '../site.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigManagerService extends ErrorHandlerBaseService {
  private document = inject(DOCUMENT);
  private transferState = inject(TransferState);
  private platformId = inject(PLATFORM_ID);
  private config = inject(CONFIG, { optional: true });
  private configKey = makeStateKey<Site[]>(CONFIGURATION_KEY);
  private siteService = inject(SiteService);
  readonly sites = signal<Site[]>([]);

  readonly isCurrentSiteUnderRootDomain = signal(false);
  readonly isCurrentSiteCollection = signal(false);
  readonly rootSite = computed(() => this.getRootSite());

  readonly activeSiteRootNamesSet = computed(
    () =>
      new Set(
        this.sites().reduce<string[]>(
          (acc, site) =>
            site.rootName &&
            site.isActive &&
            !site.canUseOwnDomain &&
            !site.isRootSite &&
            site?.rootDomainSiteId === this.rootSite()?.id
              ? [...acc, site.rootName]
              : acc,
          [],
        ),
      ),
  );

  private static readonly validSiteSettingsKeys: Set<ClientSiteSettingsKeys> = new Set(
    Object.values(ClientSiteSettingsKeys),
  );

  constructor() {
    super('ConfigManagerService');

    if (isPlatformServer(this.platformId) && this.config) {
      this.loggerService.info('[ConfigManagerService] Config is set in the transfer state on the server.');
      this.sites.set(this.config);
      this.transferState.set<Site[]>(this.configKey, this.getSitesWithClientSettingsOnly(this.sites()));
    } else if (isPlatformBrowser(this.platformId)) {
      this.sites.set(this.transferState.get<Site[]>(this.configKey, []));
    }
  }

  getSiteByRootName(rootName: string): Site | null {
    return this.sites()?.find((site: Site) => site.rootName === rootName) || null;
  }

  getSiteByCollectionName(collectionName: string): Site | null {
    const sites =
      this.sites()?.filter(
        (site: Site) =>
          site.contentCollectionCodenames && site.contentCollectionCodenames.indexOf(collectionName) !== -1,
      ) || null;

    if (sites.length == 0) {
      this.loggerService.info(`[ConfigManagerService] No any sites was retrieved for collection ${collectionName}.`);
      return null;
    }

    if (sites.length >= 2) {
      this.loggerService.warn(
        `[ConfigManagerService] More than 1 site was retrieved for collection ${collectionName}.`,
      );
    }

    return sites[0];
  }

  getRootSite(): Site | null {
    const siteByDomainName = this.getSiteByDomainName();
    const foundRootSite =
      this.sites()?.find(
        (site: Site) => site.isRootSite && site.isActive && site.id === siteByDomainName?.rootDomainSiteId,
      ) || null;

    return foundRootSite || siteByDomainName;
  }

  identifyCurrentSite(siteIdentificator?: string | null, isPreviewMode = false): Site | null {
    const domainSiteConfig: Site | null = this.getSiteByDomainName();
    const isRootSite: boolean = !!domainSiteConfig?.isRootSite;
    const isEcommerceEnabled: boolean = this.getSiteFlag(domainSiteConfig, ClientSiteSettingsKeys.EcommerceIsEnabled);
    let currentSite: Site | null = domainSiteConfig;

    this.isCurrentSiteUnderRootDomain.set(isRootSite);
    this.isCurrentSiteCollection.set(false);

    if (isRootSite && siteIdentificator && !isEcommerceEnabled) {
      const collectionSiteConfig = isPreviewMode
        ? this.getSiteByCollectionName(siteIdentificator)
        : this.getSiteByRootName(siteIdentificator);
      const isValidCollectionSiteConfig: boolean = this.siteService.isCollectionSite(
        collectionSiteConfig,
        this.rootSite()?.id,
      );

      currentSite = isValidCollectionSiteConfig ? collectionSiteConfig : domainSiteConfig;

      this.isCurrentSiteCollection.set(isValidCollectionSiteConfig);
    }

    return currentSite;
  }

  getSiteSettingValueByKey(site: Site | undefined | null, key: ClientSiteSettingsKeys): string {
    if (!site) {
      return '';
    }

    return site.siteSettings?.find((siteSetting) => siteSetting.key === key)?.value || '';
  }

  getSiteFlag(site: Site | undefined | null, key: ClientSiteSettingsKeys): boolean {
    const value = this.getSiteSettingValueByKey(site, key);

    return Boolean(Number(value));
  }

  getSiteByDomainName(): Site | null {
    const currentDomainName: string = this.document.location.host;

    return (
      this.sites()?.find((site: Site) => {
        return (
          site.canUseOwnDomain &&
          site.isActive &&
          site.domainNames?.some(({ domainNameValue }: DomainName) => domainNameValue === currentDomainName)
        );
      }) || null
    );
  }

  getRootSiteDefaultDomainName(): string | null {
    const rootSite = this.getRootSite();
    return rootSite?.domainNames?.find((site) => site.isDefault === true)?.domainNameValue ?? '';
  }

  isActiveSiteRootName(rootName: string): boolean {
    return this.activeSiteRootNamesSet().has(rootName);
  }

  private getSitesWithClientSettingsOnly(sites: Site[]): Site[] {
    return sites.map((site: Site) => {
      if (!site.siteSettings?.length) {
        return site;
      }

      const filteredSettings: SiteSetting[] = site.siteSettings.filter((setting: SiteSetting) =>
        ConfigManagerService.validSiteSettingsKeys.has(setting.key as ClientSiteSettingsKeys),
      );

      return { ...site, siteSettings: filteredSettings };
    });
  }
}
