export enum EnvVarsNames {
  ENV = 'ENV',
  NODE_API_BASE_URL = 'NODE_API_BASE_URL',
  APPLICATIONINSIGHTS_CONNECTION_STRING = 'APPLICATIONINSIGHTS_CONNECTION_STRING',
  MINIMUM_LOG_LEVEL = 'MINIMUM_LOG_LEVEL',
  APIM_BASE_URL = 'APIM_BASE_URL',
  APIM_SUBSCRIPTION_PRIMARY_KEY = 'APIM_SUBSCRIPTION_PRIMARY_KEY',
  APIM_CONTENT_API_BASE_URL = 'APIM_CONTENT_API_BASE_URL',
  APIM_CONTENT_API_SUBSCRIPTION_KEY = 'APIM_CONTENT_API_SUBSCRIPTION_KEY',
  DIDOMI_DEFAULT_CONSENT_NOTICE_ID = 'DIDOMI_DEFAULT_CONSENT_NOTICE_ID',
  GTM_DEFAULT_ID = 'GTM_DEFAULT_ID',
  GOOGLE_API_KEY = 'GOOGLE_API_KEY',
  APIM_STORE_LOCATOR_API_BASE_URL = 'APIM_STORE_LOCATOR_API_BASE_URL',
  APIM_STORE_LOCATOR_SUBSCRIPTION_KEY = 'APIM_STORE_LOCATOR_SUBSCRIPTION_KEY',
  CDP_XPRNC_API_BASE_URL = 'CDP_XPRNC_API_BASE_URL',
  DEFAULT_TIMEOUT = 'DEFAULT_TIMEOUT',
  APIM_PRODUCTLINE_API_BASE_URL = 'APIM_PRODUCTLINE_API_BASE_URL',
  APIM_PRODUCTLINE_API_SUBSCRIPTION_KEY = 'APIM_PRODUCTLINE_API_SUBSCRIPTION_KEY',
  APIM_PRODUCTLINE_FUNCTION_KEY = 'APIM_PRODUCTLINE_FUNCTION_KEY',

  APIM_PRODUCT_API_BASE_URL = 'APIM_PRODUCT_API_BASE_URL',
  APIM_PRODUCT_API_BASE_URL_V2 = 'APIM_PRODUCT_API_BASE_URL_V2',
  APIM_PRODUCT_API_SUBSCRIPTION_KEY = 'APIM_PRODUCT_API_SUBSCRIPTION_KEY',
  APIM_PRODUCT_API_API_KEY = 'APIM_PRODUCT_API_API_KEY',

  PATH_REDIRECTS_ENABLED = 'PATH_REDIRECTS_ENABLED',
  EASYREDIR_TIMEOUT = 'EASYREDIR_TIMEOUT',
}
